import { FynbosLogo, Router } from '@/Components'
import { cn, useToast } from '@/Lib'
import { PageProps } from '@/types'
import { Link, router, usePage } from '@inertiajs/react'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import { posthog } from 'posthog-js'
import { FC, ReactNode, useEffect, useMemo } from 'react'
import { Toaster } from 'sonner'
import { NavDrawer } from './NavDrawer'

const appUrl = import.meta.env.VITE_APP_URL || 'https://wealth.fynbos.app'

type LearnScaffoldProps = {
  children?: ReactNode
}

export const AppLayout: FC<LearnScaffoldProps> = ({ children }) => {
  const { hide_amounts, auth } = usePage<PageProps>().props
  useToast()

  useEffect(() => {
    posthog.identify(auth.user.id.toString(), { name: auth.user.name })
  }, [auth.user.id, auth.user.name])

  return (
    <>
      <div className='relative inset-0 flex min-h-dvh w-full flex-col bg-app lg:flex-row'>
        <nav className='hidden h-full w-72 lg:fixed lg:flex lg:flex-col'>
          <Link href='/' className='p-4'>
            <FynbosLogo />
          </Link>
          <ScrollArea.Root className='h-full w-full overflow-hidden'>
            <ScrollArea.Viewport className='h-full w-full'>
              <div className='flex max-w-72 flex-col gap-1 p-4'>
                <NavItem href={route('home')}>Home</NavItem>
                <NavItem href={route('emergency-savings.index')}>
                  Emergency savings
                </NavItem>
                <NavItem href={route('tfsa.index')}>
                  Tax-free investments
                </NavItem>
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className='flex touch-none select-none p-0.5 transition-colors duration-[160ms] ease-out hover:bg-slate-50 data-[orientation=horizontal]:h-2.5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col'
              orientation='vertical'
            >
              <ScrollArea.Thumb className="relative flex-1 rounded-[10px] bg-slate-300 before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-['']" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
          <div className='p-4'>
            <HideAmountsNavItem>
              {hide_amounts ? 'Show' : 'Hide'} balances
            </HideAmountsNavItem>
            <LogoutNavItem>Logout</LogoutNavItem>
          </div>
        </nav>
        <header className='sticky top-0 z-40 flex w-full border-b border-slate-200 bg-white/95 lg:hidden [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
          <div className='mx-auto flex w-full items-center gap-x-4 p-4 md:max-w-4xl'>
            <NavDrawer>
              <NavItem href={route('home')}>Home</NavItem>
              <NavItem href={route('emergency-savings.index')}>
                Emergency savings
              </NavItem>
              <NavItem href={route('tfsa.index')}>Tax-free investments</NavItem>

              <div className='mt-8' />
              <HideAmountsNavItem>
                {hide_amounts ? 'Show' : 'Hide'} balances
              </HideAmountsNavItem>
              <LogoutNavItem>Logout</LogoutNavItem>
            </NavDrawer>
            <Link href={'/'}>
              <FynbosLogo />
            </Link>
          </div>
        </header>
        <div className='flex min-h-dvh w-full flex-col gap-y-6 px-4 lg:pl-[19rem]'>
          <main className='mx-auto min-h-lvh w-full pt-4 lg:max-w-4xl lg:pt-20'>
            {children}
          </main>
          <footer className='mx-auto mt-auto flex w-full flex-col space-y-2 py-20 text-xs text-medium lg:max-w-4xl'>
            <p>&copy; 2024 Fynbos Inc</p>
            <h3>📜 Disclaimer:</h3>
            <ul>
              <li>
                <strong>🛠️ Purpose:</strong> The information on Fynbos is for{' '}
                <strong>educational and informational purposes only</strong>,
                not professional financial advice.
              </li>
              <li>
                <strong>📊 Accuracy & Reliability:</strong> Content is based on
                your input and aimed to help you grasp financial concepts.
                Fynbos does not guarantee its accuracy or applicability to your
                specific circumstances.
              </li>
              <li>
                <strong>🧐 Professional Advice:</strong> Proper financial
                planning involves many personal factors. Our tools help outline
                your financial trajectory, but they{' '}
                <strong>should not replace professional advice</strong>.
              </li>
              <li>
                <strong>🤝 Consultation Recommended:</strong> For a complete
                financial plan, consult a qualified financial advisor for
                guidance tailored to your needs.
              </li>
              <li>
                <strong>🔄 Current Information:</strong> We strive to keep our
                content updated but it may not reflect the most current data
                available at other institutions.
              </li>
              <li>
                <strong>🚫 No Warranties:</strong> All tools and information on
                Fynbos are provided <strong>&quot;as is&quot;</strong> without
                any warranties.
              </li>
            </ul>
          </footer>
        </div>
      </div>
      <Toaster expand position='top-right' offset={16} />
    </>
  )
}

type NavItemProps = {
  children?: ReactNode
  className?: string
  href: string
}

const NavItem: FC<NavItemProps> = ({ children, href, className }) => {
  const { url } = usePage()

  const isActive = useMemo(() => {
    const active = new URL(url, appUrl)
    return href.toLowerCase() === active.href.toLowerCase()
  }, [url, href])

  return (
    <Router
      href={href}
      className={cn(
        isActive ? 'bg-nav-active' : 'hover:bg-nav',
        'relative w-full truncate rounded-xl p-3 text-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        className
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'ListItem'

type LogoutNavItemProps = {
  children?: ReactNode
}

const LogoutNavItem: FC<LogoutNavItemProps> = ({ children }) => {
  const _handleLogout = () => {
    router.post(
      '/logout',
      {},
      {
        onSuccess: () => {
          posthog.reset()
        }
      }
    )
  }

  return (
    <div
      onClick={_handleLogout}
      className='relative w-full cursor-pointer truncate rounded-xl p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
    >
      {children}
    </div>
  )
}

LogoutNavItem.displayName = 'LogoutNavItem'

type HideAmountsNavItemProps = {
  children?: ReactNode
}

const HideAmountsNavItem: FC<HideAmountsNavItemProps> = ({ children }) => {
  const _handle = () => {
    router.post(route('toggle-hide-amounts'))
  }

  return (
    <div
      onClick={_handle}
      className='relative w-full cursor-pointer truncate rounded-xl p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
    >
      {children}
    </div>
  )
}

HideAmountsNavItem.displayName = 'HideAmountsNavItem'
